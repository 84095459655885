const places = [
  {
    title: 'Medical and Surgical',
    description:
      "High quality design and supply of medical and surgical items.",
    imageUrl: process.env.PUBLIC_URL + '/assets/medical1.jpg',
    time: 1500,
  },
  {
    title: 'About Lifeline',
    description:
      'Lifeline Enterprises is a proudly South African company. We employ locally and produce high quality items at competitive prices.',
    optionalDescription:
      'Contact: query.lifeline@gmail.com',
    imageUrl: process.env.PUBLIC_URL + '/assets/about2.jpg',
    time: 1500,
  },
];

export default places;
